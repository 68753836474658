<template>
    <section class="hero is-bold is-primary is-fullheight">
        <slot></slot>
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">Reset Password</h1>
                <form>
                    <p class="pb-3">
                        Please enter your email. You will receive a link to create a new password.
                    </p>
                    <div class="field">
                        <input v-model="email" class="input" type="email" placeholder="john@email.com" autocomplete="username">
                    </div>

                    <div class="field pb-3">
                        <button  class="button" type="submit" @click="askForReset">Reset</button>
                    </div>
                </form>
                <div v-if="isNotification">
                    <div class="notification is-warning" >                        
                        An email is sent to your <strong>email address</strong> with a verification code. If you do not receive it, please verify the junk folder.
                    </div>
                    <form>
                        <p class="pb-3">
                            Verification code 
                        </p>
                        <div class="field">
                            <input v-model="verificationCode" class="input" type="text" placeholder="1234">
                        </div>
                        <p>
                            New Password
                        </p>
                        <div class="field">
                            <input v-model="password1" class="input" type="password" placeholder="Password" pattern=".*" autocomplete="new-password">
                        </div>
                        <p>
                            Confirm New Password
                        </p>
                        <div class="field">
                            <input v-model="password2" class="input" type="password" placeholder="Confirm Password" pattern=".*" autocomplete="new-password">
                        </div>
                        <div class="field">
                            <button class="button" type="submit" @click="doReset">OK</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>           
    </section>
</template>

<script>
import {authentication} from '../shared/authentication'

export default {
    name: "SignIn",
    data() {
        return {
            email: '',
            isNotification: false,
            verificationCode:'',
            password1: '',
            password2: '',
        }
    },
    methods: {
        askForReset: function(event) {
            event.preventDefault();
            authentication.forgotPassword(this.email, this.onResetSuccess, this.onResetError);            
        },
        onResetSuccess: function() {
            this.isNotification = true;
        },
        onResetError: function(err) {            
            alert("Reset error: " + err.message);
        },
        doReset: function() {
            event.preventDefault();
            if (this.password1 != this.password2) {
                alert("The new password and confirmation password must be identical.");
                return;
            }
            authentication.confirmPassword(this.email, this.verificationCode, this.password1, this.onChangePasswordSuccess, this.onResetError);
        },
        onChangePasswordSuccess: function() {
            authentication.signin(this.email, this.password1, this.onLoginSuccess, this.onLoginError);
        },
        onLoginSuccess: function() {
            window.location.href = 'a/app.html';
        },
        onLoginError: function() {            
            alert("Login error");
        }
    }
}
</script>